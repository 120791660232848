<template>
  <!-- PROFILE BUTTON -->
  <li
    id="profileButton"
    m-dropdown-toggle="click"
    aria-expanded="true"
    class="m-nav__item m-topbar__user-profile m-topbar__user-profile--img m-dropdown m-dropdown--medium m-dropdown--arrow m-dropdown--header-bg-fill m-dropdown--align-right m-dropdown--mobile-full-width m-dropdown--skin-light"
    @click="toggleProfile()"
  >
    <a
      href="#"
      class="m-nav__link m-dropdown__toggle"
    >
      <span class="m-topbar__welcome d-table-cell lightGray verticalAlignMiddle pr-2">{{ $t('hello') }},</span>
      <span class="m-topbar__username darkGrayColor">
        {{ surname }}
        <!-- small arrows to indicate wether the profile is opened or closed -->
        <span v-if="!profileOpen">&#9660;</span>
        <span v-else>&#9650;</span>
      </span>
    </a>
    <!-- PROFILE -->
    <div :class="[profileClass, 'm-dropdown__wrapper zIndexHigh']">
      <span class="m-dropdown__arrow m-dropdown__arrow--right m-dropdown__arrow--adjust" />
      <div class="m-dropdown__inner">
        <div class="m-dropdown__header m--align-center">
          <div class="m-card-user m-card-user--skin-light">
            <div class="m-card-user__details">
              <span class="font-weight-bold">{{ fullname }}</span>
              <br>
              <span class="m-card-user__email m--font-weight-300 m-link mt-2">{{ email }}</span>
            </div>
          </div>
        </div>
        <div class="m-dropdown__body">
          <div class="m-dropdown__content">
            <ul class="m-nav m-nav--skin-light">
              <li class="m-nav__item">
                <!-- LOGOUT BUTTON -->
                <a
                  v-tooltip="'Logout'"
                  class="btn m-btn--pill btn-secondary m-btn m-btn--custom m-btn--label-brand m-btn--bolder"
                  href="#"
                  @click.prevent="authenticationLogout()"
                ><font-awesome-icon
                  class="mr-2"
                  icon="sign-out-alt"
                  style="vertical-align: middle; color: #6f727d;"
                />{{ $t('logout') }}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import { authenticationMixin } from '@/mixins/authenticationMixin';

export default {
  name: "Profile",
  mixins: [
    authenticationMixin
  ],
  computed: {
    surname: function () {
      return this.authenticationGetUser().details.profile.given_name;
    },
    fullname: function () {
      return `${ this.authenticationGetUser().details.profile.given_name } ${ this.authenticationGetUser().details.profile.family_name }`;
    },
    email: function () {
      return this.authenticationGetUser().details.profile.email;
    },
    profileClass: function () {
      if (this.profileOpen) return 'visible';
      return 'hidden';
    }
  },
  methods: {
    toggleProfile () {
      this.profileOpen = !this.profileOpen;
    }
  }
}
</script>
